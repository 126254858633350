<template>
  <header role="banner" class="mobile-header">
    <section class="contact-info-bar">
      <p>ATENDER >> ENTENDER >> RESOLVER</p>
      <a href="/orcamento" class="button">SOLICITE UM ORÇAMENTO</a>
      <nav role="navigation" aria-labelledby="menuredessociais">
        <i class="bi bi-telephone-fill"> (11) 2026-1819</i>
        <a href="https://instagram.com/soluciona.assessoria" target="_blank"><i class="bi bi-instagram"></i>Instagram</a>
        <a href="https://www.linkedin.com/company/soluciona-assessoria" target="_blank"><i
            class="bi bi-linkedin"></i>Linkedin</a>
      </nav>
    </section>
    <section class="menu">
      <img src="logo_name.png" alt="logo-nome-soluciona">
      <i class="bi bi-list" @click="this.openMenu = true"></i>
    </section>
  </header>
  <MobileMenuComponent @toggleVisibility="this.openMenu = false" v-if="this.openMenu"/>
</template>

<script>
import MobileMenuComponent from './MobileMenuComponent.vue';

export default {
  name: 'HeaderComponent',
  components: {
    MobileMenuComponent
  },
  data() {
    return {
      openMenu: false
    }
  }
}
</script>

<style scoped>
.mobile-header {
  display: none
}

.contact-info-bar {
  display: flex;
  align-items: center;
  justify-content: space-around;

  background-color: #E7CD56;
  font-weight: 600;
  flex-direction: column;
  gap: 5px;
  padding: 7px 0;
}

.contact-info-bar div {
  font-size: 1.2em;
}

.contact-info-bar p {
  margin: 0;
}

.contact-info-bar div a {
  color: black;
}

.contact-info-bar div i {
  margin: auto 5px;
}

.menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  box-shadow: 0 4px 4px 0 rgb(223, 216, 216);
  padding: 25px 0;
}

.menu img {
  width: 220px;
}

.contact-info-bar .button {
  background-color: #E7CD56;
  border: 1px solid #222220;
  border-radius: 15px;
  color: black;
  padding: 5px 15px;
  margin: auto 2px;
  transition: all .2s;
}

.contact-info-bar .button:hover {
  background-color: white;
}

.bi-list {
  font-size: 2em;
  border: 1px solid black;
  border-radius: 5px;
  padding: 3px 5px;
}

@media (max-width: 984px) {
  .mobile-header {
    display: block
  }
}
</style>
