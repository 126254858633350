<template>
  <footer role="contentinfo">
    <nav role="navigation" class="map" aria-labelledby="menurodapé">
      <h5 id="menurodapé">MAPA DO SITE</h5>
      <a href="/">HOME</a>
      <a href="/sobrenos">SOBRE NÓS</a>
      <a href="/servicos">SERVIÇOS</a>
      <a href="/clientes">CLIENTES</a>
      <a href="/parceiros">PARCEIROS</a>
      <a href="/blog">BLOG</a>
    </nav>
    <div>
      <h5>TELEFONE</h5>
      <p>(11) 2026-1819</p>
      <h5>E-MAIL</h5>
      <p>contato@soluciona.inf.br</p>
    </div>
    <div>
      <h5>ENDEREÇO</h5>
      <p>Avenida Aricanduva, 8256 - Torre 9 Sala 1 <br>
        São Paulo - SP <br>
        Cep: 03951-220</p>
    </div>
  </footer>
  <span class="copy">Copyright© Soluciona Assessoria. Todos os direitos reservados - {{ new Date().getFullYear()
    }}</span>
</template>

<script>
export default {
  name: 'FooterComponent',
}
</script>

<style scoped>
footer {
  display: flex;
  justify-content: space-around;
  padding: 65px 0;

  background: url("../../public/logo.png") no-repeat center center;
  background-size: contain;
  background-color: #100F0F;
  background-blend-mode: overlay;
}

footer h5 {
  color: #E7CD56;
  margin: 3px 0;
  font-size: 1.2em
}

footer .map {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px
}

footer .map a:focus {
  outline: 2px solid;
  outline-color: white;
  color: white!important;
}

footer .map a,
footer p {
  text-decoration: none;
  color: white;
}

.copy {
  background-color: #100F0F;
  display: block;
  color: white;
  width: 100%;
  text-align: center;
}

@media (max-width: 750px) {
  footer {
    flex-direction: column;
    align-items: center;
    gap: 15px;
    text-align: center;
  }

  footer .map {
    text-align: center;
    align-items: center
  }
}
</style>
