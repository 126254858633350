<template>
  <header role="banner" class="principal-header">
    <nav role="navigation" class="contact-info-bar" aria-labelledby="menucontato">
      <p>ATENDER >> ENTENDER >> RESOLVER</p>
      <router-link role="link" class="button" to="/orcamento">SOLICITE UM ORÇAMENTO</router-link>
      <div>
        <i class="bi bi-telephone-fill"> (11) 2026-1819</i>
        <a role="link" href="https://www.youtube.com/@SolucionaTecnologia" target="_blank"><i
            class="bi bi-youtube"></i>Youtube</a>
        <a role="link" href="https://www.linkedin.com/company/soluciona-assessoria" target="_blank"><i
            class="bi bi-linkedin"></i>Linkedin</a>
      </div>
    </nav>
    <section class="menu">
      <img src="../../public/logo_name.png" alt="Imagem escrito 'Soluciona'">
      <nav role="navigation" aria-labelledby="menuprincipal" @keydown="this.handleKeyDown()">
        <router-link role="link" class="menuItem" to="/">HOME</router-link>
        <router-link role="link" class="menuItem" to="/sobrenos">SOBRE NÓS</router-link>
        <router-link role="link" class="menuItem" to="/servicos">SERVIÇOS</router-link>
        <router-link role="link" class="menuItem" to="/clientes">CLIENTES</router-link>
        <router-link role="link" class="menuItem" to="/parceiros">PARCEIROS</router-link>
        <router-link role="link" class="menuItem" to="/ti360">TI 360</router-link>
        <a class="menuItem youtube-button" href="https://www.youtube.com/@SolucionaTecnologia" target="_blank"><i
            class="bi bi-youtube"></i>YOUTUBE</a>
        <router-link class="blog-button" to="/blog">BLOG</router-link>
      </nav>
    </section>
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent',
  methods: {
    handleKeyDown() {
      if (event.key === 'Enter') {
        // Na navegação com "TAB" do teclado, ao selecionar uma opção do menu, achei mais viável
        // Remover o foco do menu e focar no botão de pular para conteúdo, para facilitar a navegação
        const btnPularParaConteudo = document.getElementById('pularparaconteudo')
        setTimeout(() => {
          btnPularParaConteudo.focus()
        }, 200)
      }
    }
  }
}
</script>

<style scoped>
.contact-info-bar {
  display: flex;
  align-items: center;
  justify-content: space-around;

  background-color: #E7CD56;
  font-weight: 600;
}

.contact-info-bar div {
  font-size: 1.4em;
}

.contact-info-bar div a {
  color: black;
}

.contact-info-bar div i {
  margin: auto 5px;
}

.menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  box-shadow: 0 4px 4px 0 rgb(223, 216, 216);
  padding: 25px 0;
}

.menu img {
  width: 220px;
}

.menu .menuItem {
  display: inline-block;
  font-weight: bold;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background .2s;
  cursor: pointer;
  color: black;
}


.menu .blog-button {
  background-color: #E7CD56;
  border: 1px solid #E7CD56;
  border-radius: 5px;
  padding: 10px 15px;
  margin: auto 2px;
  transition: background .3s;
  font-weight: bold;
  color: black;
}

.menu .blog-button:hover {
  background-color: white;
}

.contact-info-bar .button {
  background-color: #E7CD56;
  border: 1px solid #222220;
  border-radius: 15px;
  color: black;
  padding: 10px 15px;
  margin: auto 2px;
  transition: all .2s;
}

.contact-info-bar .button:hover {
  background-color: white;
}

.menu .youtube-button {
  display: inline-flex;
  gap: 5px;
  background-color: rgb(208 34 34);
  border: 1px solid rgb(255 0 0);
  ;
  color: white;
  border-radius: 5px;
  padding: 10px 15px;
  margin: auto 10px;
  transition: background .3s;
  font-weight: bold;
}


.menu .youtube-button:hover {
  background-color: white;
  color: rgb(255 0 0);
  ;
}

.bi-youtube {
  font-size: 1.2em;
}

@media (max-width: 984px) {
  .principal-header {
    display: none
  }
}
</style>
