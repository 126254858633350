import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/sobrenos',
    name: 'sobrenos',
    component: () => import('../views/SobreNosView.vue')
  },
  {
    path: '/servicos',
    name: 'servicos',
    component: () => import('../views/ServicosView.vue')
  },
  {
    path: '/clientes',
    name: 'clientes',
    component: () => import('../views/ClientesView.vue')
  },
  {
    path: '/parceiros',
    name: 'parceiros',
    component: () => import('../views/ParceirosView.vue')
  },
  {
    path: '/ti360',
    name: 'ti360',
    component: () => import('../views/Ti360View.vue')
  },
  {
    path: '/orcamento',
    name: 'orcamento',
    component: () => import('../views/OrcamentoView.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../views/BlogView.vue')
  },
  {
    path: '/post/:id',
    name: 'Post',
    component: () => import('../views/PostView.vue')
  },
  {
    path: '/chatbot',
    name: 'chatbot',
    component: () => import('../views/ChatbotView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router