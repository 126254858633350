<template>
    <a href="#content" class="pularparaconteudo" id="pularparaconteudo" tabindex="0">Pular para conteúdo</a>
</template>
<style scoped>
.pularparaconteudo {
    opacity: 0;
    position: absolute;
    padding: 15px;
    z-index: 999;
    border: 1px solid black;
    margin: 2px;
    background-color: white !important;
    color: black;
}

.pularparaconteudo:focus {
    opacity: 1;
}
</style>